<template>
  <div class="enterprise wrapper">
    <a-spin :spinning="loading">
      <div class="">
        <a-empty v-if="enterprise.length == 0" description="暂无企业"></a-empty>
        <div v-else class="flex flex-between enterprise-box-main">
          <template v-for="(item, index) in enterprise" :key="index">
            <div
              class="enterprise-item flex flex-between"
              @click="goToEnterpriseDetail(item)"
            >
              <div>
                <div class="enterprise-title">{{ item.enterprise_name }}</div>
                <div class="flex">
                  <div class="text-gray font-16">
                    {{ item.enterprise_create_time }}提交
                  </div>
                  <div
                    :class="{
                      'defalut-label': true,
                      'label-gray': item.enterprise_leave_status == 2,
                      'label-red':
                        item.enterprise_enter_status == -1 ||
                        item.enterprise_enter_status == -3 ||
                        item.enterprise_enter_status == -4 ||
                        item.enterprise_leave_status == -1,
                      'label-green':
                        item.enterprise_enter_status == 4 &&
                        item.enterprise_leave_status == 0,
                    }"
                  >
                    {{
                      transferStatus(
                        item.enterprise_enter_status,
                        item.enterprise_leave_status
                      )
                    }}
                  </div>
                  <!-- 0等待初审中；-1初审未通过；1待提交复审；2等待复审中；-3复审未通过；3待签署合同；-4未合同签署；4已完成入驻 -->
                </div>
              </div>
              <right-outlined />
            </div>
          </template>
        </div>
        <a-button
          block
          size="large"
          type="primary"
          class="btn"
          @click="goApplyPage"
        >
          申请入驻
        </a-button>
      </div>
    </a-spin>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch
} from "@vue/runtime-core";
import { getEnterpriseList2 } from "@/api/main.js";
import { message } from "ant-design-vue";
import url from "@/utils/url.js";
import { getUserId, getUserData } from "@/utils/storeData.js";
import { encode } from "js-base64";
import { RightOutlined } from "@ant-design/icons-vue";
const leaveStatusMap = {
  1: {
    text: "退园待审核",
    color: "#1276cb"
  },
  "-1": {
    text: "退园已驳回",
    color: "#d63030"
  },
  2: {
    text: "已退园",
    color: "#9d9d9d"
  }
};
const statusMap = {
  0: {
    text: "等待审核中",
    color: "#1276cb"
  },
  "-1": {
    text: "审核未通过",
    color: "#d63030"
  },
  1: {
    text: "待提交复审",
    color: "#1276cb"
  },
  2: {
    text: "等待复审中",
    color: "#1276cb"
  },
  "-3": {
    text: "复审未通过",
    color: "#d63030"
  },
  3: {
    text: "待签署合同",
    color: "#1276cb"
  },
  "-4": {
    text: "未合同签署",
    color: "#d63030"
  },
  4: {
    text: "已完成入驻",
    color: "#73e350"
  }
};

export default defineComponent({
  name: "Enterprise",
  components: {
    RightOutlined
  },
  setup() {
    const pageData = reactive({
      enterprise: [],
      user_id: computed(() => getUserId()),
      userData: computed(() => getUserData()),
      loading: false
    });
    const getEnterpriseListFun = () => {
      pageData.loading = true;
      getEnterpriseList2({
        park_id: pageData.userData?.user_park_id,
        user_id: pageData.user_id
      })
        .then(res => {
          if (res.code == 200) {
            pageData.enterprise = res.data.enterprise;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    const goToEnterpriseDetail = _item => {
      if (
        _item.enterprise_enter_status == 4 &&
        _item.enterprise_leave_status == 0
      ) {
        url.navigateTo("/enterprise/detail", {
          t: encode(`${_item.enterprise_id}`)
        });
      } else {
        url.navigateTo("/enterprise/flow", {
          t: encode(`${_item.enterprise_id}`)
        });
      }
    };
    const goApplyPage = () => {
      url.navigateTo("/enterprise/apply");
    };
    const transferStatus = (
      enterprise_enter_status,
      enterprise_leave_status
    ) => {
      if (enterprise_leave_status != 0) {
        // return "已退园";
        return leaveStatusMap[enterprise_leave_status].text;
      } else {
        return statusMap[enterprise_enter_status].text;
      }
    };
    watch(
      [
        () => pageData.userData.user_park_id,
        () => pageData.page,
        () => pageData.limit
      ],
      val => {
        getEnterpriseListFun();
      },
      {
        immediate: true,
        deep: true
      }
    );

    return {
      ...toRefs(pageData),
      goToEnterpriseDetail,
      goApplyPage,
      statusMap,
      transferStatus,
      leaveStatusMap
    };
  }
});
</script>
<style lang="less" scoped>
.enterprise {
  padding-top: 20px;
  padding-bottom: 50px;
  box-sizing: border-box;
  .enterprise-box-main {
    flex-wrap: wrap;
    .enterprise-item {
      width: 570px;
      height: 110px;
      border-bottom: 2px solid #dfdfdf;
      cursor: pointer;
      .enterprise-title {
        color: #212531;
        font-size: 18px;
        margin-bottom: 10px;
        width: 500px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .defalut-label {
        padding: 0 10px;
        height: 22px;
        line-height: 22px;
        text-align: center;
        border-radius: 11px;
        border: solid 1px #1276cb;
        color: #1276cb;
        margin-left: 20px;
      }
    }
  }
}
</style>
